<div class="countdown-banner-layout">
    <div class="deal-text-block">
        <i class="fa fa-bolt icon"></i>
        <span class="text">
            صفقة سريعة
        </span>
        <i class="fa fa-bolt icon"></i>
    </div>
    
    <div class="timer-block" *ngIf="timeLeftTillOfferEnds">
        <span class="timer-text">
            {{'ends in'| translate}}
        </span>
        <!-- <cd-timer class="clock" format="user" countdown="true" [startTime]="timeLeftTillOfferEnds">[days] {{'time.days' | translate}} [hours] {{'time.hours' | translate}} [minutes] {{'time.minutes' | translate}} [seconds] {{'time.seconds' | translate}}</cd-timer> -->
        <cd-timer class="clock" format="user" countdown="true" [startTime]="timeLeftTillOfferEnds">[days]:[hours]:[minutes]:[seconds] </cd-timer>
    </div>
</div>

