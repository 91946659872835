import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from './common.service';

@Injectable({
  providedIn: "root",
})
export class CouponesService {
  public _couponesUrl =
    this.commonService._hostName + "coupons.php?action=";
  // public _couponesUrl = "https://zahrastore.net/affiliate-develop/web-develop/app-api/coupons.php?action=";

  constructor(private commonService: CommonService, private http: HttpClient) {}

  getCouponsList(mode: string = "all", start = 0, aItemsPerPage = 20) {
    return this.http.get<any>(
      this._couponesUrl +
        "getCouponsList" +
        "&start=" +
        start +
        "&aItemsPerPage=" +
        aItemsPerPage +
        "&mode=" +
        mode
    );
  }

  addUseCoupon(code: any, mode = 'cart', cartId: any) {
    let obj1 = {
      code: code,
      mode: mode,
      mode_id: cartId,
    };
    let body = JSON.stringify(obj1);
    return this.http.post<any>(this._couponesUrl + "add_use_coupon", body);
  }
  deleteUseCoupon(couponId: any, cartId: any) {
    let obj1 = {
      id: couponId,
      cart_id: cartId
    };
    let body = JSON.stringify(obj1);
    return this.http.post<any>(this._couponesUrl + "delete_use_coupon", body);
  }

}
