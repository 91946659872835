import {
  BrowserModule,
  BrowserTransferStateModule,
  Title,
} from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule, PLATFORM_ID } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { ToastrModule } from "ngx-toastr";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { SharedModule } from "./shared/shared.module";
import { ShopModule } from "./shop/shop.module";
import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";
import { ShopComponent } from "./shop/shop.component";
import { PagesComponent } from "./pages/pages.component";
import { ElementsComponent } from "./elements/elements.component";

import { TransferHttpCacheModule } from "@nguniversal/common";

import $ from "jquery";
import { TokenInterceptorService } from "src/token-interceptor.service";
import { InlineStyleComponent } from "./shared/inline-style/inline-style.component";
import { InlineStyleModule } from "./shared/inline-style/inline-style.module";
import { MasterApiService } from "./shared/services/master-api.service";

import { environment } from "src/environments/environment";

// Angular Firebase Services
import {
  provideAnalytics,
  getAnalytics,
  ScreenTrackingService,
  UserTrackingService,
} from "@angular/fire/analytics";
import { provideAuth, getAuth } from "@angular/fire/auth";
import { provideDatabase, getDatabase } from "@angular/fire/database";
import { provideFunctions, getFunctions } from "@angular/fire/functions";
import { provideMessaging, getMessaging } from "@angular/fire/messaging";
import { providePerformance, getPerformance } from "@angular/fire/performance";
import {
  provideRemoteConfig,
  getRemoteConfig,
} from "@angular/fire/remote-config";
import { provideFirebaseApp, initializeApp } from "@angular/fire/app";
import { provideStorage, getStorage } from "@angular/fire/storage";
import { NotificationModalComponent } from "./shared/components/modal/notification-modal/notification-modal.component";

// import { PixelModule } from "ngx-multi-pixel";
// import { CdTimerModule } from "angular-cd-timer";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { FormsModule } from "@angular/forms";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";
// AoT requires an exported function for factories

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function initializeApi(masterApiService: MasterApiService) {
  return (): Promise<void> => {
    return masterApiService.initialize().then(() => {
      const hostName = masterApiService.masterData.site_url + "/web-api/";
      const settingsUrl = hostName + "settings.php?action=";
      return masterApiService.getAllContact(settingsUrl);
    });
  };
}
@NgModule({
  declarations: [
    AppComponent,
    ShopComponent,
    PagesComponent,
    ElementsComponent,
    NotificationModalComponent,
  ],
  imports: [
    FormsModule,
    MatBottomSheetModule,
    // PixelModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    TransferHttpCacheModule,
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    InlineStyleModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: false,
      enableHtml: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule,
    ShopModule,
    AppRoutingModule,
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase()),
    provideFunctions(() => getFunctions()),
    provideMessaging(() => getMessaging()),
    providePerformance(() => getPerformance()),
    provideRemoteConfig(() => getRemoteConfig()),
    provideStorage(() => getStorage()),
    // CdTimerModule,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "outline" },
    },
    MasterApiService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApi,
      deps: [MasterApiService],
      multi: true,
    },

    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    ScreenTrackingService,
    UserTrackingService,
    NotificationModalComponent,
  ],

  bootstrap: [InlineStyleComponent, AppComponent],
})
export class AppModule {}
