import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss']
})
export class CountdownTimerComponent implements OnInit {

  @Input() offerEndDate: any;

  isBrowser: any;

  timeLeftTillOfferEnds: number;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {
    // console.log('this.offerEndDate', this.offerEndDate);
    if (isPlatformBrowser(this.platformId)) {
      this.getTimeLeftOfferEnds(this.offerEndDate);
    }
    
    
  }

  getTimeLeftOfferEnds(dateEnd){
    var x = new Date();
    var y = new Date(dateEnd);
    let seconds = Math.abs(x.getTime() - y.getTime())/1000;
    
    // seconds = ~~seconds;
    // console.log('number of seconds ', seconds);
    this.timeLeftTillOfferEnds = seconds;
  }

}
